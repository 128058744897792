@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.theme--light .v-card__text {
  color: #000;
}

.v-card {
  display: flex;
  flex-direction: column;
}

.v-card__actions {
  border-top: 1px solid $grey-200;
}

.v-card__text {
  flex-grow: 1;
}

.v-card__subtitle {
  height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-image {
  flex-grow: 0;
}

.video-wrap {
  background-color: $grey-100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.video {
  width: 100%;
}
